<template>
  <div class="Base-Div">
    <h1 class="Feeds-title">FEEDS</h1>
    <div class="video-list">
      <div
        class="video-container"
        v-for="(video, index) in getUserInfo()"
        :key="index"
        :data-embed-url="video.embedURL"
        :href="`#${index}`"
      >
        <div class="user-info">
          <img :src="video.profilePicture" alt="User Picture" class="user-picture" />
          <a :href="`https://www.vulcano2k.com/user/${encodeURIComponent(video.profileName)}`" class="profile-name">{{ video.profileName }}</a>
        </div>
        <!-- Preview Video -->
        <video
          v-if="!video.embedPlaying"
          :src="video.previewURL"
          class="responsive-video"
          @ended="playEmbedVideo(index)"
          @click="playEmbedVideo(index)"
          @touchstart="onTouchStart"
          @touchend="onTouchEnd(index)"      
          preload="none"          
          muted
          
        ></video>
        
        <!-- Embed Video -->
        <iframe
          v-else
          :src="video.embedURL"
          frameborder="0"
          allowfullscreen
          class="responsive-video"          
          allow="autoplay; encrypted-media"
          sandbox="allow-same-origin allow-scripts"
          @load="removeAds" 
          ref="videoIframe"
        ></iframe>
        <div class="interaction-buttons">
          <button class="thumbs-up" @click="toggleThumbsUp(index)">
            <i :class="{'fas fa-thumbs-up': true, active: isThumbsUpActive(index)}"></i>
          </button>
          <button class="thumbs-down" @click="toggleThumbsDown(index)">
            <i :class="{'fas fa-thumbs-down': true, active: isThumbsDownActive(index)}"></i>
          </button>
          <div
            :class="{
              'thumbs-up-text': thumbsUpCounts[index] === 1,
              'thumbs-down-text': thumbsDownCounts[index] === 1,
              'neutral-text': thumbsUpCounts[index] === 0 && thumbsDownCounts[index] === 0
            }"
          >
            {{ getThumbsStatus(index) }}
          </div>
        </div>
        <div class="Hash-container">
          <div v-for="(hashtag, idx) in video.hashtags" :key="idx">
            <a :href="`https://www.vulcano2k.com/hashtag/${hashtag}`">{{ hashtag }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted, nextTick } from 'vue';

const BASE_URL = 'https://strapi.vulcano2k.com';

export default {
  name: 'UserProfile',
  setup() {
    const userVideos = ref([]);
    const thumbsUpCounts = ref([]);
    const thumbsDownCounts = ref([]);
    const videoRef = ref(null); // variavel para diferenciar o toque do arrastre
    const touchStartCoords = ref({ x: 0, y: 0 });

    onMounted(() => {
      console.log('MOUNTING');
      fetchUserData();
    });

    const fetchUserData = async () => {
      console.log('FECHING USER DATA');
      try {
        const response = await axios.get(`${BASE_URL}/api/user-video-embeds/?populate[user_profile][populate][ProfilePicture][fields][0]=url&populate[hashtags]=true&populate[VideoPreview]=true`);
        userVideos.value = response.data.data;
        // Log the entire response data to inspect it
        console.log('API Response Data:', response.data);

        userVideos.value.forEach(() => {
          thumbsUpCounts.value.push(0);
          thumbsDownCounts.value.push(0);
        });
        await nextTick();
        setupIntersectionObserver();
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const getUserInfo = () => {
      console.log('GETUSERINFO function');
      return userVideos.value.map((item) => {
        return {
          embedURL: getEmbedURL(item),
          profileName: getProfileName(item),
          profilePicture: getProfilePicture(item),
          userId: getUserId(item),
          hashtags: getHashtags(item),
          previewURL: getVideoPreviewURL(item),
          embedPlaying: item.embedPlaying,
        };
      });
    };

    const getEmbedURL = (item) => item.attributes?.embedURL;    

    const getVideoPreviewURL = (item) => {
    const videoPreviewData = item.attributes?.VideoPreview?.data;

    // If videoPreviewData exists and is an array with at least one element
    if (videoPreviewData && Array.isArray(videoPreviewData) && videoPreviewData.length > 0) {
      const previewAttributes = videoPreviewData[0].attributes;
      const previewUrl = previewAttributes?.url;
      
      // Construct and return the full URL
      return `${BASE_URL}${previewUrl}`;
    }

    // If there's no video preview available
    return null;
  };

    const getProfileName = (item) => {
      const userProfile = item.attributes?.user_profile?.data?.attributes || 'W/O userProfile associated';
      return userProfile.ProfileName;
    };

    const getProfilePicture = (item) => {
      const userProfile = item.attributes?.user_profile?.data?.attributes;
      //console.log(`${BASE_URL}${userProfile.ProfilePicture.data.attributes}`)
      if (userProfile && userProfile.ProfilePicture?.data?.attributes?.url) {
        return `${BASE_URL}${userProfile.ProfilePicture.data.attributes.url}`;
      }
      return ''; // Return an empty string if no profile picture URL is available
    };

    const getUserId = (item) => item.attributes?.user_profile?.data?.id;

    const getHashtags = (item) => item.attributes?.hashtags?.data.map((hashtag) => hashtag.attributes.HashCategorie) || [];

    const toggleThumbsUp = (index) => {
      thumbsUpCounts.value[index] = thumbsUpCounts.value[index] === 0 ? 1 : 0;
      if (thumbsUpCounts.value[index] === 1) {
        thumbsDownCounts.value[index] = 0; // Ensure thumbs down is reset
      }
    };

    const isThumbsUpActive = (index) => {
      return thumbsUpCounts.value[index] === 1;
    };

    const toggleThumbsDown = (index) => {
      thumbsDownCounts.value[index] = thumbsDownCounts.value[index] === 0 ? 1 : 0;
      if (thumbsDownCounts.value[index] === 1) {
        thumbsUpCounts.value[index] = 0; // Ensure thumbs up is reset
      }
    };

    const isThumbsDownActive = (index) => {
      return thumbsDownCounts.value[index] === 1;
    };

    const getThumbsStatus = (index) => {
  if (thumbsUpCounts.value[index] === 1) {
    return "Me Gusta!";
  } else if (thumbsDownCounts.value[index] === 1) {
    return "Not really";
  } else {
    return "";
  }
};

const setupIntersectionObserver = () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const video = entry.target;  // Referência ao elemento de vídeo de preview

      if (entry.intersectionRatio > 0.65) {  // Quando 65% do vídeo estiver visível
        if (video.paused) {  // Se o vídeo estiver pausado, começamos a tocar
          video.play();
          console.log(`Vídeo de preview com URL: ${video.src} está tocando.`);
        }
      } else {
        // Se o vídeo sair do viewport, pausamos o vídeo para economizar recursos
        video.pause();
        video.currentTime = 0;  // Opcional: Reiniciar o vídeo ao sair do viewport
        console.log(`Vídeo de preview com URL: ${video.src} está pausado e resetado.`);
      }
    });
  }, {
    threshold: [0.65]  // O vídeo precisa estar 65% visível para começar a tocar
  });

  // Aqui selecionamos todos os vídeos de preview para serem observados
  document.querySelectorAll('.responsive-video').forEach((video) => {
    observer.observe(video);
  });
};  const playEmbedVideo = (index) => {
  userVideos.value[index].embedPlaying = true;
  };


  //const touchStartCoords = ref({ x: 0, y: 0 }); // Para armazenar as coordenadas iniciais
    const distanceThreshold = 30; // Defina aqui o valor de distância mínima

    // Captura as coordenadas do touchstart
    const onTouchStart = (event) => {
      touchStartCoords.value = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      };
    };

    // Verifica no touchend se a distância percorrida é maior que o threshold
    const onTouchEnd = (event, index) => {
      const touchEndCoords = {
        x: event.changedTouches[0].clientX,
        y: event.changedTouches[0].clientY,
      };

      // Verifica se a distância em X ou Y é maior que o threshold
      const xDiff = Math.abs(touchEndCoords.x - touchStartCoords.value.x);
      const yDiff = Math.abs(touchEndCoords.y - touchStartCoords.value.y);
      

      if (xDiff > distanceThreshold || yDiff > distanceThreshold) {
        playEmbedVideo(index); // Executa a ação de trocar o vídeo
      }
    };
 




    return {
      userVideos,
      getUserInfo,
      thumbsUpCounts,
      thumbsDownCounts, // Return thumbsDownCounts
      toggleThumbsUp,
      toggleThumbsDown, // Return toggleThumbsDown
      getThumbsStatus,
      isThumbsUpActive,
      isThumbsDownActive,
      playEmbedVideo,
      videoRef,
      onTouchStart,
      onTouchEnd,
      
      
    };
  },
};
</script>

<style>

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.main-content {
  padding: 0 !important; /* O "!important" garante que essa regra seja aplicada, sobrescrevendo outras */
  margin: 0; /* Se houver algum margin também */
}

.Base-Div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;  
  box-sizing: border-box;
  padding: 5px;
  margin: 0
}

.Feeds-title{
  display: flex;
  font-size: 3vw;
  align-items: flex-start;
  width: 100%;
}

.video-list { /*Todos os videos */
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 10px;
}

.video-container {  /*Video + User Profile + Buttons + tags */
  position: relative;
  width: 100%;  
  min-width: 200px;
  min-height: 350px;
  aspect-ratio: 16/9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  margin-top: 2vw;
  padding:5px
}

.video-container:not(:first-child) {
  margin-top: 30px; /* Aumenta a margem de todos os vídeos, exceto o primeiro */
  padding: 50px; /* Aumenta o padding de todos os vídeos, exceto o primeiro */
}


.video-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.user-info {
  justify-content: flex-start;
  display: flex;
  align-items: left;
  margin-bottom: 10px;  
  flex-wrap: nowrap; /* Prevents vertical wrapping */
  width: 100%;
  box-sizing: border-box;
  gap: 10px;
  max-width: 800px;
  
}

.user-picture {
  align-items: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-name {
  flex-grow: 1;
  flex-shrink: 1;
  font-family: Arial, sans-serif;
  color: hsl(0, 92%, 20%);
  font-size: 3vw;
}

.responsive-video {
  width: 100%;
  height: auto;
  max-width: 800px;
}

.interaction-buttons {
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  margin-left: 0;
  width: 100%;
  
}

.thumbs-up,
.thumbs-down {
  display: flex;
  justify-content: flex-start;
  font-size: 2vw;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.thumbs-up-text {
  color: rgb(133, 44, 155);  /* Cor para "Sim" */
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 1.5em;
}

.thumbs-down-text {
  color: rgb(77, 77, 77);  /* Cor para "Não" */
  font-weight: bold;
  font-family: 'Verdana', sans-serif;
  font-size: 1.5em;
}

.thumbs-up:active,
.thumbs-down:active {
  background-color: white;
  color: black;
}

.fas.fa-thumbs-up.active {
  color: rgb(0, 124, 182);
}

.fas.fa-thumbs-down.active {
  color: red; /* Change the color as desired for the active state */
}

.Hash-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
  width: 100%;
  max-width: 800px;
  border: 1px solid #ccc; /* Linha fina e cinza */
  padding: 1.4vw; /* Para evitar que o conteúdo encoste na borda */
  box-sizing: border-box; /* Inclui o padding e a borda no cálculo da largura */
}

.Hash-container a {
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 2vw;
}

/****************************************************************MOBILE*********************************************************************************/
@media only screen and (max-width: 767px) {
  .stories-title {
  font-size: 4vw;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.main-content {
  padding: 0 !important; /* O "!important" garante que essa regra seja aplicada, sobrescrevendo outras */
  margin: 0; /* Se houver algum margin também */
}

.Base-Div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  
  box-sizing: border-box;
  padding: 5px;
  margin: 0
}

.Feeds-title{
  display: flex;
  font-size: 6vw;
  align-items: flex-start;
  width: 100%;
}

.video-list { /*Todos os videos */
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 10px;
}

.video-container {  /*Video + User Profile + Buttons + tags */
  position: relative;
  width: 100%;  
  min-width: 200px;
  min-height: 350px;
  aspect-ratio: 16/9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  margin-top: 2vw;
  padding:5px
}

.video-container:not(:first-child) {
  margin-top: 30px; /* Aumenta a margem de todos os vídeos, exceto o primeiro */
  padding: 30px; /* Aumenta o padding de todos os vídeos, exceto o primeiro */
}


.video-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.user-info {
  justify-content: flex-start;
  display: flex;
  align-items: left;
  margin-bottom: 10px;  
  flex-wrap: nowrap; /* Prevents vertical wrapping */
  width: 100%;
  box-sizing: border-box;
  gap: 10px;
  max-width: 800px;
  
}

.user-picture {
  align-items: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-name {
  flex-grow: 1;
  flex-shrink: 1;
  font-family: Arial, sans-serif;
  color: hsl(0, 92%, 20%);
  font-size: 4vw;
}

.responsive-video {
  width: 100%;
  height: auto;
  max-width: 800px;
}

.interaction-buttons {
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  margin-left: 0;
  width: 100%;
  
}

.thumbs-up,
.thumbs-down {
  display: flex;
  justify-content: flex-start;
  font-size: 3vw;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.thumbs-up-text {
  color: rgb(133, 44, 155);  /* Cor para "Sim" */
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 4vw;
}

.thumbs-down-text {
  color: rgb(77, 77, 77);  /* Cor para "Não" */
  font-weight: bold;
  font-family: 'Verdana', sans-serif;
  font-size: 4vw;
}

.thumbs-up:active,
.thumbs-down:active {
  background-color: white;
  color: black;
}

.fas.fa-thumbs-up.active {
  color: rgb(0, 124, 182);
}

.fas.fa-thumbs-down.active {
  color: red; /* Change the color as desired for the active state */
}

.Hash-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
  width: 100%;
  max-width: 800px;
  border: 1px solid #ccc; /* Linha fina e cinza */
  padding: 1.4vw; /* Para evitar que o conteúdo encoste na borda */
  box-sizing: border-box; /* Inclui o padding e a borda no cálculo da largura */
}

.Hash-container a {
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 2vw;
}

}
</style>
