<template>
  <div class="photo-gallery">
    <h1 class="stories-title">STORIES</h1>
    <div class="photo-list-wrapper">
      <button class="scroll-button left" @click="scrollLeft">&#8249;</button>
      <div class="photo-list-container" ref="photoListContainer">
        <div class="photo-list">
          <div class="photo-container" v-for="picture in pictures" :key="picture.id" @click="navigateToUserProfile(picture.userProfile.name)">
            <div>
              <img :src="picture.url" :alt="picture.userProfile.name" class="circle-photo" />
            </div>
            <div class="category-name">
              {{ picture.userProfile.name }}
            </div>
          </div>
        </div>
      </div>
      <button class="scroll-button right" @click="scrollRight">&#8250;</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

const BASE_URL = 'https://strapi.vulcano2k.com'

export default {
  data() {
    return {
      pictures: []
    };
  },
  created() {
    this.fetchPictures();
  },
  methods: {
    async fetchPictures() {
      try {
        const response = await axios.get(`${BASE_URL}/api/user-profiles?populate=*`);
        
        if (response.data && response.data.data) {          
          this.pictures = response.data.data.map(item => {
            const userProfile = item.attributes;
            const profilePicture = userProfile.ProfilePicture?.data;
            if (profilePicture) {
              return {
                id: profilePicture.id,
                url: BASE_URL + profilePicture.attributes.url, // Construct full URL
                userProfile: {
                  id: item.id,
                  name: userProfile.ProfileName
                }
              };
            } else {
              return null;
            }
          }).filter(picture => picture !== null);
        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error('Error fetching pictures:', error);
      }
    },
    navigateToUserProfile(profileName) {
      this.$router.push({ name: 'UserProfile', params: { id: profileName } });
    },
    scrollLeft() {
      const container = this.$refs.photoListContainer;
      container.scrollBy({
        top: 0,
        left: -600,
        behavior: 'smooth'
      });
    },
    scrollRight() {
      const container = this.$refs.photoListContainer;
      container.scrollBy({
        top: 0,
        left: 600,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style>


.stories-title {
  font-size: 4vw;

}

.photo-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.photo-list-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.photo-list-container {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.photo-list-container::-webkit-scrollbar {
  display: none;
}

.photo-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  transition: scroll 0.3s ease;
}

.photo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  margin-left: 10px;
  padding-left: 4vw;
}

.circle-photo {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.category-name {
  text-align: center;
  color: hsl(0, 92%, 20%);
  font-size: 2.5vw;
  margin-top: 0;
  padding: 0;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: rgb(237, 18, 18);
  font-size: 58px;
  cursor: pointer;
  z-index: 2;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

.scroll-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/****************************************************************MOBILE*********************************************************************************/
@media only screen and (max-width: 767px) {
  .stories-title {
  font-size: 4vw;
}

.photo-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.photo-list-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.photo-list-container {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.photo-list-container::-webkit-scrollbar {
  display: none;
}

.photo-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  transition: scroll 0.3s ease;
  height: 100px;
}

.photo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  margin-left: 10px;
  padding-left: 4vw;
}

.circle-photo {
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.category-name {
  text-align: center;
  color: hsl(0, 92%, 20%);
  font-size: 3.5vw;
  margin-top: 0;
  padding: 0;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: rgb(237, 18, 18);
  font-size: 58px;
  cursor: pointer;
  z-index: 2;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

.scroll-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

}
</style>
