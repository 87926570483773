<template>
  <div v-if="hashtagInfo">
    <div class="hashtag-info">      
      <div class="hashtag-details">
        <h1 class="hashtag-name">{{ getProfileName() }}</h1>
      </div>
    </div>
    <div>
      <div class="tabs">        
        <button @click="selectedTab = 'videos'" :class="{ active: selectedTab === 'videos' }">Videos</button>
        <button @click="selectedTab = 'pictures'" :class="{ active: selectedTab === 'pictures' }">Pictures</button>
      </div>
      <div class="picture-content">
        <div v-if="selectedTab === 'pictures'" class="grid">
          <div v-for="(pictureUrl, index) in getUserPictureUrls()" :key="index" class="grid-item" @click="openImageModal(pictureUrl)">
            <img :src="pictureUrl" alt="User Picture" />
          </div>
        </div>
      </div>
      <div class="video-content">
        <div v-if="selectedTab === 'videos'" class="grid">
          <div v-for="(video, index) in getUserVideos()" :key="index" class="grid-item">
            <iframe
              :src="video.embedURL"
              frameborder="0"
              allowfullscreen
              class="responsive-video"
            ></iframe>
          </div>
        </div>
      </div>
      <ImageModal :isOpen="isImageModalOpen" @close="closeImageModal">
        <img :src="selectedImage" alt="Selected" />
      </ImageModal>
      <VideoModal :isOpen="isVideoModalOpen" :videoSrc="selectedVideo || ''" @close="closeVideoModal" />
    </div>
  </div>
  <div v-else>
    <p>Loading...</p>
  </div>
</template>

<script>
import axios from 'axios';
import ImageModal from './ImageModal.vue';
import VideoModal from './VideoModal.vue';

const BASE_URL = 'https://strapi.vulcano2k.com'

export default {
  name: 'HashtagProfile',
  components: {
    ImageModal,
    VideoModal,
  },
  props: ['id'],
  data() {
    return {
      selectedTab: 'videos',
      isImageModalOpen: false,
      isVideoModalOpen: false,
      selectedImage: null,
      selectedVideo: null,
      hashtagInfo: {},
    };
  },
  created() {
    this.fetchHashtagData();
  },
  watch: {
    id: 'fetchHashtagData',
  },
  methods: {
    async fetchHashtagData() {
      try {
        const requestURL = `${BASE_URL}/api/hashtags?filters[HashCategorie][$eq]=${this.id}&populate[user_video_embeds]=*`;
        console.log("Request URL:", requestURL);
        const response = await axios.get(requestURL);
        console.log("RESPOOONSEE URL:", response);        
        this.hashtagInfo = response.data.data[0];
        console.log(this.hashtagInfo);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    getProfileName() {
      return this.hashtagInfo?.attributes?.HashCategorie; 
    },
    getUserPictureUrls() {
      return this.hashtagInfo?.attributes?.user_picture_s?.data?.flatMap(picture =>
        picture.attributes.UserPicture?.data?.map(userPicture => `${BASE_URL}${userPicture.attributes.url}`) || []
      ) || [];
    },
    getUserVideos() {
      const videos = this.hashtagInfo?.attributes?.user_video_embeds?.data || [];
      return videos.map(video => {
        const embedURL = video.attributes?.embedURL || 'W/O embedURL';
        return {
          embedURL,
        };
      });
    },
    openImageModal(imageSrc) {
      this.selectedImage = imageSrc;
      this.isImageModalOpen = true;
    },
    closeImageModal() {
      this.isImageModalOpen = false;
      this.selectedImage = null;
    },
    openVideoModal(videoSrc) {
      this.selectedVideo = videoSrc;
      this.isVideoModalOpen = true;
    },
    closeVideoModal() {
      this.isVideoModalOpen = false;
      this.selectedVideo = null;
    },
  },
};
</script>

<style scoped>
.hashtag-info {
  display: flex;
  margin: 20px 0;
  align-items: center;
}

.hashtag-details {
  flex: 1;
}

.hashtag-name {
  margin: 0;
  color: hsl(0, 92%, 20%);
  font-family: 'Arial', sans-serif;
  font-size: 2em;
  font-weight: bold;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tabs button {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  background-color: #333;
  color: white;
  border: none;
  outline: none;
}

.tabs button.active {
  background-color: #555;
}

.picture-content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  width: 100%;
}

.grid-item {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background-color: #000; /* Optional: background color for empty grid cells */
}

.grid-item iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
