<template>
    <header class="header">
      <div class="logo">
        <h1>
          <a href="http://localhost:8080" class="site-name">Vulcano 2k</a>
        </h1>
          <span class="version">v0.0.0b</span>
      </div>
      <div class="search-bar">
        <input class="search-box" type="text" placeholder="Search here your desire..." v-model="searchQuery">
        <button @click="search">Search</button>
      </div>
    </header>
  </template>
  
  <script>
  export default {
    data() {
      return {
        searchQuery: ''
      };
    },
    methods: {
      search() {
        alert(`Searching for: ${this.searchQuery}`);
        // Implement search functionality here
      },
    }
  };
  </script>
  
  <style>
  .header {
    display: flex;
    justify-content: space-between;
    background-color: #000000;
    color: rgb(0, 0, 0);
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Isso garante que padding e bordas sejam incluídos nas dimensões de um elemento */
  }

  .site-name {
  color: hsl(0, 92%, 20%); /* Cor vinho escura */
  font-size: 7vw; /* Ajusta o tamanho da fonte de acordo com a largura da viewport */
  display: flex;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.version {
  font-size: 2vw;
  color: #888;
  margin-left: 10px;
  display: inline-block;
  line-height: 7vw; /* Ajuste para alinhar com a altura do nome do site */
  vertical-align: middle; /* Isso tende a alinhar melhor o texto com o centro do nome */
}

.search-bar {
  display: flex;
  align-items: center;
  width: 50%;
}

.search-bar input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 0.5vw;
  color: white;
  background-color: #333;
  border: 1px solid #555;
  font-size: 1.8vw;
}

.search-bar button {
  padding: 0.5vw 1vw;
  background-color: #555;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.5vw;
}

.search-bar button:hover {
  background-color: #777;
}

/****************************************************************MOBILE*********************************************************************************/
@media only screen and (max-width: 767px) {
}

.header {
    display: flex;
    justify-content: space-between;
    background-color: #000000;
    color: rgb(0, 0, 0);
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Isso garante que padding e bordas sejam incluídos nas dimensões de um elemento */
  }

  .site-name {
  color: hsl(0, 92%, 20%); /* Cor vinho escura */
  font-size: 7vw; /* Ajusta o tamanho da fonte de acordo com a largura da viewport */
  display: flex;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.version {
  font-size: 2vw;
  color: #888;
  margin-left: 10px;
  display: inline-block;
  line-height: 7vw; /* Ajuste para alinhar com a altura do nome do site */
  vertical-align: middle; /* Isso tende a alinhar melhor o texto com o centro do nome */
}

.search-bar {
  display: flex;
  align-items: center;
  width: 50%;
}

.search-bar input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 0.5vw;
  color: white;
  background-color: #333;
  border: 1px solid #555;
  font-size: 1.8vw;
}

.search-bar button {
  padding: 0.5vw 1vw;
  background-color: #555;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.5vw;
}

.search-bar button:hover {
  background-color: #777;
}


  </style>
  