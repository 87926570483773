<template>
  <div v-if="userInfo">
    <div class="user-info">
      <img :src="getProfilePictureUrl()" alt="User Picture" class="user-picture" />
      <div class="user-details">
        <h1 class="user-name">{{ getProfileName() }}</h1>
        <div class="user-hashtags">
          <span v-if="getUserHashtags().length > 0">
            <a v-for="(hashtag, index) in getUserHashtags()" :key="index" :href="hashtag.url" class="hashtag-link">
              {{ hashtag.text }}
            </a>
          </span>
          <span v-else>No hashtags available</span>
        </div>
      </div>
    </div>
    <div>
      <div class="tabs">        
        <button @click="selectedTab = 'videos'" :class="{ active: selectedTab === 'videos' }">Videos</button>
        <button @click="selectedTab = 'pictures'" :class="{ active: selectedTab === 'pictures' }">Pictures</button>
      </div>
      <div class="picture-content">
        <div v-if="selectedTab === 'pictures'" class="grid">
          <div v-for="(pictureUrl, index) in getUserPictureUrls()" :key="index" class="grid-item" @click="openImageModal(pictureUrl)">
            <img :src="pictureUrl" alt="User Picture" />
          </div>
        </div>
      </div>
      <div class="video-content">
        <div v-if="selectedTab === 'videos'" class="grid">
          <div v-for="(video, index) in getUserVideos()" :key="index" class="grid-item">
            <iframe
              :src="video.embedURL"
              frameborder="0"
              allowfullscreen
              class="responsive-video"
            ></iframe>
          </div>
        </div>
      </div>
      <ImageModal :isOpen="isImageModalOpen" @close="closeImageModal">
        <img :src="selectedImage" alt="Selected" />
      </ImageModal>
      <VideoModal :isOpen="isVideoModalOpen" :videoSrc="selectedVideo || ''" @close="closeVideoModal" />
    </div>
  </div>
  <div v-else>
    <p>Loading...</p>
  </div>
</template>



<script>
import axios from 'axios';
import ImageModal from './ImageModal.vue';
import VideoModal from './VideoModal.vue';

const BASE_URL = 'https://strapi.vulcano2k.com';

export default {
  name: 'UserProfile',
  components: {
    ImageModal,
    VideoModal,
  },
  props: ['id'],
  data() {
    return {
      selectedTab: 'videos',
      isImageModalOpen: false,
      isVideoModalOpen: false,
      selectedImage: null,
      selectedVideo: null,
      userInfo: {},
    };
  },
  created() {
    this.fetchUserData();
  },
  watch: {
    id: 'fetchUserData',
  },
  methods: {

    async fetchUserData() {
      try {
        const response = await axios.get(`${BASE_URL}/api/user-profiles`, {
          params: {
            filters: {
              ProfileName: {
                $eq: this.id, // Ensure this.id is unique for the specific user
              },
            },
            populate: {
              user_video_embeds: {
                populate: {
                  hashtags: true,
                },
              },
              user_picture_s: {
                populate: {
                  UserPicture: true,
                },
              },
              ProfilePicture: true,
            },
          },
        });

        if (response.data.data.length > 0) {
          this.userInfo = response.data.data[0]; // Select the first result (should be only one)
          console.log('User Info:', this.userInfo);
        } else {
          console.log('No user profile found with the given ProfileName');
          this.userInfo = null; // Handle cases where no user is found
        }        
        console.log(this.userInfo);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    
    getProfilePictureUrl() {
      const profilePicture = this.userInfo?.attributes?.ProfilePicture?.data?.attributes?.url;
      return profilePicture ? `${BASE_URL}${profilePicture}` : ''; // Default value if no picture is available
    },
    getUserPictureUrls() {
      return this.userInfo?.attributes?.user_picture_s?.data?.flatMap(picture =>
        picture.attributes.UserPicture?.data?.map(userPicture => `${BASE_URL}${userPicture.attributes.url}`) || []
      ) || [];
    },
    getProfileName() {
      return this.userInfo?.attributes?.ProfileName; 
    },
    getUserVideos() {
      const videos = this.userInfo?.attributes?.user_video_embeds?.data || [];
      return videos.map(video => {
        const embedURL = video.attributes?.embedURL || 'W/O embedURL';
        return {
          embedURL,
        };
      });
    },

    getUserHashtags() {
      const videos = this.userInfo?.attributes?.user_video_embeds?.data || [];
      
      const allHashtags = videos.reduce((acc, video) => {
        const videoHashtags = video.attributes.hashtags.data.map(tag => ({
          text: tag.attributes.HashCategorie,
          url: `https://www.vulcano2k.com/hashtag/${encodeURIComponent(tag.attributes.HashCategorie)}`
        }));
        
        return acc.concat(videoHashtags);
      }, []);
      
      // Remove duplicates based on the text property
      const uniqueHashtags = [...new Map(allHashtags.map(item => [item.text, item])).values()];
      
      return uniqueHashtags; // Return an array of unique hashtag objects
    },

    openImageModal(imageSrc) {
      this.selectedImage = imageSrc;
      this.isImageModalOpen = true;
    },
    closeImageModal() {
      this.isImageModalOpen = false;
      this.selectedImage = null;
    },
    openVideoModal(videoSrc) {
      this.selectedVideo = videoSrc;
      this.isVideoModalOpen = true;
    },
    closeVideoModal() {
      this.isVideoModalOpen = false;
      this.selectedVideo = null;
    },
  },
};
</script>


<style scoped>

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tabs button {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  background-color: #333;
  color: white;
  border: none;
  outline: none;
}

.tabs button.active {
  background-color: #555;
}

.picture-content {
  display: flex;
  justify-content: left;
  width: 100%;  
}

.user-info {
  display: flex;    
  align-items: center; /* Ensures vertical alignment */
  width: 100%; /* Ensures it takes the full width of its container */
  padding: 10px; /* Optional: adds some spacing around the user info */
}

.user-picture {
  width: 150px;
  height: 150px;
  margin-right: 20px;
}

.user-details {
  display: flex;
  flex-direction: column; /* Stack username and hashtags vertically */
}

.user-name {
  margin: 0 ;  
  color: hsl(0, 92%, 20%);
  font-family: 'Arial', sans-serif;
  font-size: 2em;
  font-weight: bold;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  width: 100%;
}

.grid-item {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background-color: #000; /* Optional: background color for empty grid cells */
}

.grid-item iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.grid-item img,
.grid-item video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  }

  .hashtag-link {
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
    margin-left: 10px; /* Add space between hashtags */
    display: inline-block; /* Ensure hashtags are inline */
  }

</style>
